<template>
<div class="timeWrapperBox">
    <div class="timeWrapper" v-if="starOrEnd == 1">
        <div class="time">Launchpad begins in <span>{{showTime}}</span></div>
    </div>
    <div class="timeWrapper" v-else-if="starOrEnd == 2">
        <div class="time">Launchpad ends in <span>{{showTime}}</span></div>
    </div>
    <div class="timeWrapper" v-else-if="starOrEnd == 3">
        <div class="time">Token allocated in <span>{{showTime}}</span></div>
    </div>
    <div class="timeWrapper" v-else-if="starOrEnd == 4">
        
    </div>
</div>
</template>

<script>
export default {
    name: '',
    props: {

        startime: {
            // type: Number ,
            default: '0'
        },
        endtime: {
            // type: Number ,
            default: '0'
        },
        tokentime: {
            // type: Number ,
            default: '0'
        },
        access: {
            // type: Number ,
            default: '0'
        },
    },
    data () {
        return {
            starOrEnd:1,
            type:0,
            showTime:'00',trueTime:0,
            timer: null,
            timers:null,
            zeroCount:3000,
            PositiveTime:3000,
            starTimeA:'',
            tokenTimeA:'',
            endTimeA:'',
            accessA:'',
        }
    },

    methods: {
        init () {
            this.starTimeA = this.startime
            this.tokenTimeA = this.tokentime
            this.endTimeA = this.endtime
            this.accessA = this.access
            if (this.accessA == 0) {
                if(this.starTimeA> 0){
                    this.trueTime = this.starTimeA;
                    this.starOrEnd = 1
                    this.startimers = setInterval(() => {
                        this.starTimeA--
                        if (this.starTimeA*1000 < 0) {
                            this.trueTime = Number(this.endTimeA) - Number(this.startime)
                            this.starOrEnd = 2
                            this.$forceUpdate()
                            clearInterval(this.startimers)
                        }
                    }, 1000);
                } else if (this.endTimeA > 0) {
                    this.trueTime = this.endTimeA
                    this.starOrEnd = 2
                    this.endtimers = setInterval(() => {
                        this.endTimeA--
                        if (this.endTimeA*1000 < 0) {
                            this.trueTime = Number(this.tokenTimeA) - Number(this.endtime)
                            this.starOrEnd = 3
                            this.$forceUpdate()
                            clearInterval(this.endtimers)
                        }
                    }, 1000);
                } else if (this.tokenTimeA>0) {
                    this.trueTime = this.tokenTimeA
                    this.starOrEnd = 3
                    this.tokentimers = setInterval(() => {
                        this.tokenTimeA--
                        if (this.tokenTimeA*1000 < 0) {
                            this.starOrEnd = 4
                            this.$forceUpdate()
                            clearInterval(this.tokentimers)
                        }
                    }, 1000);
                } else{
                    this.starOrEnd = 4
                }
            } else {
                if(this.starTimeA> 0){
                    this.trueTime = this.starTimeA;
                    this.starOrEnd = 1
                    this.startimers = setInterval(() => {
                        this.starTimeA--
                        if (this.starTimeA*1000 < 0) {
                            this.trueTime = Number(this.endTimeA) - Number(this.startime)
                            this.starOrEnd = 3
                            this.$forceUpdate()
                            clearInterval(this.startimers)
                        }
                    }, 1000);
                } else if (this.endTimeA > 0) {
                    this.trueTime = this.endTimeA
                    this.starOrEnd = 3
                    this.endtimers = setInterval(() => {
                        this.endTimeA--
                        if (this.endTimeA*1000 < 0) {
                            this.trueTime = Number(this.tokenTimeA) - Number(this.endtime)
                            this.starOrEnd = 4
                            this.$forceUpdate()
                            clearInterval(this.endtimers)
                        }
                    }, 1000);
                } else{
                    this.starOrEnd = 4
                }
            }
            
            
            this.computeTime();
        },
        computeTime () {
            this.showTime =  this.InitTime(this.trueTime);
            this.trueTime--;
            this.timer = setInterval(() => {
                this.showTime =  this.InitTime(this.trueTime);
                if(this.trueTime<0){
                    clearInterval(this.timer);
                    this.trueTime=0;
                    this.timingTime();
                }
                this.trueTime--;
            }, 1000)
        },
        timingTime () {
            this.showTime =  this.InitTime(this.trueTime);
            this.trueTime++;
            this.timer = setInterval(() => {
                this.showTime =  this.InitTime(this.trueTime);
                this.trueTime ++;
            }, 1000)
        },

        InitTime(time){ 
            var dd,hh,mm,ss = null;
            if(time<=0){
                return "00d 00h 00m 00s"
            }else{
                dd = Math.floor(time / 60 / 60 / 24);
                hh = Math.floor((time / 60 / 60) % 24);
                mm = Math.floor((time / 60) % 60);
                ss = Math.floor(time % 60);
                if (hh < 10 ){hh = "0" + hh ;}
                if (mm < 10 ){mm = "0" + mm ;}
                if (ss < 10 ){ss = "0" + ss ;}
                if(dd==0&&hh==0&&mm==0){
                    var str = "00d 00h 00m "+ss+"s"
                }
                else if(dd==0&&hh==0){
                    var str = "00d 00h "+mm + "m " +ss+"s"
                }
                else if(dd==0){
                    str =  "00d "+hh+"h "+mm+"m "+ss+"s"
                }else{
                    str = dd+"d "+hh+"h "+mm+"m "+ss+"s";
                }
                return str;
            }
        },
    },
    mounted () {
        this.init();
    }
}
</script>

<style lang="scss" scoped>
.time{
    width: 90%;
    height: 38px;
    line-height: 38px;
    margin-left: 5%;
    margin-top: 10px;
    text-align: center;
    font-size: 0.9rem;
    font-family: Arial;
    font-weight: 400;
    color: rgba($color: #feffbc, $alpha: .5);
    background: #25134F;
    border: 1px solid transparent;
    border-radius: 0px 0px 10px 10px;
    span{
        color: #feffbc;
    }
}
</style>
